<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense class="tc-title">
      <v-toolbar-title>New Question - {{ companyname }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn style="margin-left:700px;" class="logi ma-5 mt-8 center" dark color="indigo"
        @click="goto_copyquestions()">
        Copy Questions
      </v-btn>
    </v-toolbar>
    <v-dialog v-model="linstening" width="300">
<v-card  >
  
    <center><v-icon class="ma-6" color="blue" size="100"> mdi mdi-ear-hearing</v-icon><br/>
    <span class="ma-2"> Listening ..</span>
    <br/>
    {{ runtimeTranscription_ }}
    </center>
   
</v-card>
    </v-dialog>
    <v-card>
      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="4"></v-col>
          <v-col v-if="editrightpresent" class="d-flex" cols="12" sm="4">
            <v-select :items="type_list" item-value="id" item-text="name" v-model="selected_type" outlined
              label="Question Type" @change="fetchquestiontype()"></v-select>
          </v-col>
        </v-row>

        <div v-show="selected_div != ''">
          <v-toolbar flat dense class="tc-title">
            <v-toolbar-title>{{ selected_div }}</v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-col cols="12" sm="12"> 
              <v-text-field outlined label="Question Statement" append-icon="mdi mdi-microphone" @click:append="startSpeechToTxt" required v-model="question_stmt" ></v-text-field>
            </v-col>

            <v-col cols="3" sm="3">
              <label style="color: gray; font-size: 15px;">Sort Order :</label>
              <v-text-field required outlined v-model="sort_order"></v-text-field>
            </v-col><br>
            
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <label style="color: gray; font-size: 15px;">Remark :</label>
              <v-text-field required outlined v-model="remark"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <br>

        <div v-show="selected_div === 'single word'">
          <div v-for="(input, index) in inputs" :key="index">
            <v-col cols="12" sm="6">
              <v-label>Option {{ index + 1 }}</v-label>
              <v-row class="mr-10">
                <v-text-field type="text" outlined v-model="input.answer">
                </v-text-field>
              </v-row>
              <v-label>Remark {{ index + 1 }}</v-label>
              <v-row>
                <v-text-field type="text" outlined v-model="input.option_remark">
                </v-text-field>
                <v-btn color="red" fab small dark @click="deleteRow(index)" class="del">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </div>
          <div v-show="this.optionsize <= 14">
            <v-btn color="primary" class="logi" fab small dark @click="addRow">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <center>
            <v-btn color="warning" class="logi del" @click="savequestionMCQ" dark>Save</v-btn>
          </center>
        </div>
        <div v-show="selected_div === 'Descriptive'">
          <v-row>
            <v-col cols="12" sm="12" align="center">
              <v-btn color="warning" class="logi del" @click="savequestion" dark>Save</v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-show="selected_div === 'attachment'">
          <v-row>
            <v-col cols="12" sm="12" align="center">
              <v-btn color="warning" class="logi del" @click="savequestion" dark>Save</v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-show="selected_div === 'Image'">
          <v-row>
            <v-col cols="12" sm="8">
              <v-file-input accept=".jpg," label="Choose image (max size: 2MB)" outlined show-size
                v-model="QuestionImageFile">
              </v-file-input>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn color="warning" @click="savequestion" class="logi del" dark>Upload</v-btn>
            </v-col>
          </v-row>
        </div>
        <br />
        <template v-if="this.question_list != null">
          <v-toolbar flat dense class="card-title">
            <v-toolbar-title>Already Added Questions</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-simple-table responsive>
            <tr>
              <!-- <th class="text-left">Q.No.</th> -->
              <th class="text-left">Question Statement</th>
              <th class="text-left">Question Options</th>
              <th class="text-left">Question Options Remarks</th>
              <th class="text-left">Sort Order</th>
              <th class="text-left">Question Type</th>
              <th class="text-left">Remarks</th>
              <th class="text-left">Actions</th>
            </tr>
            <tbody>
              <tr v-for="(que, index) in this.question_list" :key="index" draggable="true" @dragstart="start"
                @dragover="dragover(que[0])">
                <!-- <td>
                                {{ index+1}}
                            </td> -->
                <td>
                  {{ que[0].question_stmt }}
                </td>
                <td v-if="que[0].option_list != ''">
                  <span v-for="(op,index) in que[0].option_list" :key="index">
                    {{ op.answer }}<span v-if="que[0].option_list.length-1 !=index">,</span>
                  </span>
                </td>
                <td v-else>
                  NA
                </td>
                <td v-if="que[0].option_list != ''">
                  <span v-for="(op,index) in que[0].option_list" :key="index">
                    {{ op.option_remark }}<span v-if="que[0].option_list.length-1 !=index">,</span>
                  </span>
                </td>
                <td v-else>
                  NA
                </td>
                <td>
                  {{ que[0].sort_order }}
                </td>
                <td>
                  {{ que[0].question_type }}
                </td>
                <td>
                  {{ que[0].remark }}
                </td>
                <td>
                  <v-avatar v-if="editrightpresent" style="" size="25" class="edit-avatar">
                    <v-icon class="edit-v-icon mr-2" @click="editItem(que[0])">edit</v-icon>
                  </v-avatar>
                  <v-avatar v-if="editrightpresent" style="" size="25" class="delete-avatar">
                    <v-icon class="delete-v-icon mr-2" @click="deleteItem(que[0].id)">delete</v-icon>
                  </v-avatar>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
        <center> <v-btn class="primary" @click="save_order"> Save Order
          </v-btn></center>
        <v-layout row justify-center>
          <v-dialog v-model="dialog" persistent max-width="900px" lazy absolute>
            <v-card>
              <template v-if="this.selectedQuestion != null && this.selectfor == 'view'">
                <v-card-text class="headline grey lighten-2" primary-title>
                  {{ this.selectedQuestion.question_stmt }}
                </v-card-text>

                <template v-if="this.selectedQuestion.question_type === 'single word'">
                  <div v-for="opt in this.selectedQuestion.option_list" :key="opt.id">
                    <v-card-text>
                      <template>
                        ${answer}
                      </template>
                    </v-card-text>
                  </div>
                </template>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-if="selectedQuestion != null && selectfor == 'edit'">
                <v-card-title class="headline grey lighten-2" primary-title>
                  Edit Question{{ selectedQuestion.question_type }}
                </v-card-title>

                <v-row>
                  <v-col cols="1" sm="1"> </v-col>
                  <v-col cols="12" sm="10">
                    <div v-show="selectedQuestion.question_type != 'Image'">
                      <v-row>
                        <v-col cols="12" sm="10">
                          <v-text-field outlined label="Question Statement" required v-model="selQuestionStmt"
                            type="text"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-show="sort_order != 'Image'">
                      <v-row>
                        <!-- <v-col cols="3" sm="10">
                        <v-text-field outlined label="Sort Order"  v-model="sort_order" type="text" readonly></v-text-field>
                    </v-col>  -->
                        <label style="font-size: 17px;padding-left: 14px;"> Sort Order : </label>
                        <br />
                        <span style="font-size: 18px;margin-left:10px;font-weight:bold">{{ sort_order }}</span>
                      </v-row>
                    </div>
                    <v-row>
                        <v-col cols="12" sm="10">
                          <v-text-field outlined label="Remark" required v-model="remark"
                            type="text"></v-text-field>
                        </v-col>
                      </v-row>
                    <!-- <div v-show="selectedQuestion.question_type === 'single word'">
                <div id="app">
                    <div v-for="(input, index) in inputs" :key="index">
                        <v-col cols="12" sm="8">
                        <v-label>Option {{index+1}}</v-label>
                            <v-row>
                                <v-text-field type="text" outlined v-model="input.answer"> </v-text-field>
                                
                                <v-col cols="1" sm="1"> </v-col> 
                                <v-btn color="primary" @click="deleteRow(input.answer)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-row> 
                        
                        </v-col>  
                    </div>
                    <v-icon color="primary" class="add-v-icon mr-2" @click="addRow">add </v-icon>Add Option -->
                    <!-- <div v-show="optionsize <= 4">
                        <v-btn color="primary" @click="addRow" class="logi">ADD Options</v-btn>
                    </div>
                </div>
            </div>  -->

                    <div v-show="selectedQuestion.ismcq === true">
                      <div id="app">
                        <div v-for="(input, index) in inputs" :key="index">
                          <v-col cols="12" sm="8">
                            <v-label>Option {{ index + 1 }}</v-label>
                            <v-row class="mr-10">
                              <v-text-field type="text" outlined v-model="input.answer">
                              </v-text-field>
                            </v-row>
                            <v-label>Remark {{ index + 1 }}</v-label>
                            <v-row>
                              <v-text-field type="text" outlined v-model="input.option_remark">
                              </v-text-field>
                              <v-col cols="1" sm="1"> </v-col>
                              <v-btn color="primary" @click="deleteRow(index)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-row>
                          </v-col>
                        </div>

                        <div>
                          <v-btn color="primary" @click="addRow" class="logi">ADD Options</v-btn>
                        </div>
                      </div>
                    </div>

                    <div v-show="selectedQuestion.question_type === 'Image'">
                      <v-col cols="10" sm="10">
                        <v-card-text>
                          <v-file-input accept=".jpg," label="Choose image (max size: 2MB)" outlined show-size
                            v-model="QuestionImageFile">
                          </v-file-input>
                        </v-card-text>
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="1" sm="1"> </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="primary" @click="dialog = false">
                    Close
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="editquestions">
                    Save
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
    </v-card>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialog_up" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Please Wait !!!
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    runtimeTranscription_: "",
       transcription_: [],
       lang_: "en-IN",
       linstening:false,
    scheduling_id: "",
    optionsize: 0,
    editrightpresent: false,
    inputs: [],
    dialog_up: false,
    selectedQuestion: null,
    snackbar_msg: "",
    remark: "",
    snackbar: false,
    color: "",
    org: null,
    search: "",
    edit_btn_load: false,
    selected_company: null,
    selected_round: null,
    selected_type: "",
    selected_div: "",
    question_data: null,
    question_stmt: null,
    QuestionImageFile: null,
    questiondata: "",
    dialog: false,
    selected: null,
    selectfor: null,
    selected_all: "",
    question_list: null,
    companyname: null,
    company_list: null,
    round_list: null,
    type_list: null,
    status_list: null,
    selQuestionStmt: null,
    sort_order: null,
    selQuestionAnswer: null,
    selQuestionId: null,

    editedIndex: -1,
    editedItem: {
      company: "",
      round: "",
      type: "",
      remark: "",
      status: "",
    },
    defaultItem: {
      company: "",
      round: "",
      type: "",
      remark: "",
      status: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    startSpeechToTxt() {
    // initialisation of voicereco
    
    window.SpeechRecognition =
    window.SpeechRecognition || 
    window.webkitSpeechRecognition;
    const recognition = new window.SpeechRecognition();
    recognition.lang = this.lang_;
    recognition.interimResults = true;
this.linstening=true;
    // event current voice reco word
    recognition.addEventListener("result", event => {      
      var text = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join("");
      this.runtimeTranscription_ = text;
    });
    // end of transcription
    recognition.addEventListener("end", () => {
      this.question_stmt=this.runtimeTranscription_;
      this.runtimeTranscription_ = "";
      recognition.stop();
      this.linstening=false;
    });
     recognition.start();
   },

    save_order() {
      console.log(this.question_list);
      var qlist = []
      for (var i in this.question_list) {
        var temp = {
          "id": this.question_list[i][0].id,
          "order": this.question_list[i][0].sort_order,
        }
        qlist.push(temp);
      }
      var data = {
        data: qlist
      };
      axios
        .post("/AdminMaster/savequestionorder", data)
        .then((res) => {
          if (res.data.status == "200") {
            this.showSnackbar("green", res.data.msg);
            return

          } else {
            this.showSnackbar("red", res.data.msg);
          }
        })

    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    updateEmployee(value, authorityid) {
      this.selected_emp.push(value);
      this.selected_emp.push(authorityid);
    },
    addquestions() {
      this.$router.push("/student-addquestion");
    },
    addRow() {
      this.inputs.push({
        option_remark: "",
        answer: "",
        id: "",
      });
      this.optionsize++;
    },
    deleteRow(index) {
      let result = confirm("Do you really want to delete?");
      if(result==true){
        let innew = this.inputs[index].answer;
        this.inputs.splice(index, 1); 
        this.optionsize--;
        
      var data = {
        option: innew,
        selQuestionId: this.selectedQuestion.id,
        selQuestionStmt: this.selectedQuestion.question_stmt,
      };
      axios
        .post("/AdminMaster/deleteOfferingoption", data)
        .then((res) => {
          //window.console.log(res);
          if (res.data.code == "200") {
            this.dialog = false;
            this.showSnackbar("#4caf50", "Question Deleted Successfully.");
            this.onload();
          } else {
            this.message = res.data.message;
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
      }else{
        return false;
      }
    },
    fetchquestiontype() {
      this.inputs = [];
      for (let x in this.type_list) {
        if (this.type_list[x].id == this.selected_type) {
          this.selected_div = this.type_list[x].name;
          if (
            this.type_list[x].ismultiplechoise == true ||
            this.type_list[x].ischoiceaplicable == true
          ) {
            this.selected_div = "single word";
            if (this.selected_div == "single word") {
              this.inputs.push({
                option_remark: "",
                answer: "",
                id: "",
              });
              this.optionsize++;
            }
            break;
          } else if (
            this.type_list[x].is_attachmentrequired == true ||
            this.type_list[x].is_attachment_with_description == true
          ) {
            this.selected_div = "attachment";
            break;
          }
        }
      }
    },
    editItem(item) {
      // console.log("In Edit");
      // console.log(item);
      this.selectedQuestion = Object.assign({}, item);
      this.selectfor = "edit";
      this.inputs = [];
      this.optionsize = 0;
      this.selQuestionId = this.selectedQuestion.id;
      this.selQuestionStmt = this.selectedQuestion.question_stmt;
      this.remark = this.selectedQuestion.remark;
      this.sort_order = item.sort_order;
      for (let x in this.selectedQuestion.option_list) {
        this.inputs.push({
          option_remark: this.selectedQuestion.option_list[x].option_remark,
          answer: this.selectedQuestion.option_list[x].answer,
          id: this.selectedQuestion.option_list[x].id,
        });
        this.optionsize++;
      }
      this.dialog = true;
    },

    editquestions() {
      const data = {
        optionlist: this.inputs,
        question_id: this.selectedQuestion.id,
        question_stmt: this.selQuestionStmt,
        sort_order: this.sort_order,
        remark: this.remark,
        question_type: this.selectedQuestion.question_type.name,
      };
      axios
        .post("/AdminMaster/editOfferingQuestion", data)
        .then((res) => {
          //window.console.log(res);
          if (res.data.code == "200") {
            this.dialog = false;
            this.onload();
            this.showSnackbar("#4caf50", res.data.msg);
          } else {
            this.message = res.data.msg;
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
    },

    editElementItem(item) {
      const data = {
        question_id: item,
      };
      axios
        .post("/AdminMaster/editQuestion", data)
        .then((res) => {
          //window.console.log(res);
          if (res.data.msg == "200") {
            this.question_list = res.data.question_list;
            //this.courseauthoritylevels=res.data.courseauthoritylevels;
            this.showSnackbar("#4caf50", res.data.message);
          } else {
            this.message = res.data.message;
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
    },
    deleteItem(item) {
      const data = {
        question_id: item,
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .post("/AdminMaster/deleteOfferingQuestion", data)
          .then((res) => {
            // window.console.log(res);
            if (res.data.msg == "200") {
              this.questiondata = res.data.question_list;
              this.question_list = res.data.question_list;
              //this.courseauthoritylevels=res.data.courseauthoritylevels;
              this.showSnackbar("#4caf50", "Question Deleted Successfully");
              this.onload();
            } else {
              this.message = res.data.message;
            }
          })
          .catch((error) => {
            window.console.log("error:" + error);
          })
          .finally(() => (this.fetch_org_load = false));
      }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    savequestion() {
      //alert("in save 1")
      //alert("sort order "+sort_order)
      if (this.sort_order == null) {
        this.showSnackbar("#a61300", "Enter Sort order !!!");
        return;
      }
      if (this.question_stmt == null) {
        this.showSnackbar("#a61300", "Enter Question Statement !!!");
      } else {
        var formData = new FormData();
        const data = {
          scheduling_id: this.scheduling_id,
          question_type_id: this.selected_type,
          sort_order: this.sort_order,
          remark: this.remark,
          question_stmt: this.question_stmt,
        };
        axios
          .post("/AdminMaster/saveOfferingQuestion", data)
          .then((res) => {
            // window.console.log(res);
            if (res.data.msg == "200") {
              this.questiondata = res.data.question_list;
              this.question_list = res.data.question_list;
              //this.courseauthoritylevels=res.data.courseauthoritylevels;
              this.showSnackbar("#4caf50", res.data.message);
              this.question_stmt = null;
              this.question_data = null;
              this.inputs = [];
              this.onload();
            } else {
              this.message = res.data.message;
            }
          })

          .catch((error) => {
            window.console.log("error:" + error);
          })
          .finally(() => (this.fetch_org_load = false));
      }
    },
    goto_copyquestions() {
      var routerpush = this.$router.resolve({
        name: "copy-offering-questions",
      });
      window.open(routerpush.href, "_blank");
    },
    start() {
      this.row = event.target;
    },
    dragover(item) {
      var e = event;
      e.preventDefault();
      let children = Array.from(e.target.parentNode.parentNode.children);
      if (children.indexOf(e.target.parentNode) > children.indexOf(this.row))
        e.target.parentNode.after(this.row);
      else e.target.parentNode.before(this.row);
      item.sort_order = children.indexOf(this.row) + 1;
    },
    savequestionMCQ() {
      //alert("in save 2")
      // alert("sort order "+this.sort_order)
      if (this.sort_order == null) {
        this.showSnackbar("#a61300", "Enter Sort order !!!");
        return;
      }
      if (this.question_stmt == null) {
        this.showSnackbar("#a61300", "Enter Question Statement !!!");
      } else if (this.inputs[0].answer == "") {
        this.showSnackbar("#a61300", "Enter options !!!");
      } else {
        var formData = new FormData();
        const data = {
          optionlist: this.inputs,
          scheduling_id: this.scheduling_id,
          question_type_id: this.selected_type,
          sort_order: this.sort_order,
          remark: this.remark,
          question_stmt: this.question_stmt,
        };
        axios
          .post("/AdminMaster/saveOfferingQuestion", data)
          .then((res) => {
            //window.console.log(res);
            if (res.data.msg == "200") {
              this.questiondata = res.data.question_list;
              this.question_list = res.data.question_list;
              this.sort_order = res.data.sort_order;
              this.question_stmt = null;
              this.question_data = null;

              this.showSnackbar("#4caf50", res.data.message);
            } else if (res.data.msg == "no") {
              this.showSnackbar("#b71c1c", res.data.message);
            } else {
              this.message = res.data.message;
            }
          })

          .catch((error) => {
            window.console.log("error:" + error);
          })
          .finally(() => (this.fetch_org_load = false));
      }
    },
    onload() {
      const data = {
        offering: this.scheduling_id,
      };
      axios
        .post("/AdminMaster/addOfferingQuestion", data)
        .then((res) => {
          this.sort_order = res.data.sort_order;
          this.type_list = res.data.question_type_list;
          this.editrightpresent = res.data.editrightpresent;
          this.question_list = res.data.question_list;
          this.companyname = res.data.companyname;
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
        })
        .finally(() => { });
    },
  },

  mounted() {
    this.scheduling_id = this.$route.params.scheduling_id;
    if (
      this.scheduling_id != null &&
      this.scheduling_id != "undefined" &&
      this.scheduling_id != undefined &&
      this.scheduling_id != "null" &&
      this.scheduling_id != ""
    ) {
      localStorage.setItem("scheduling_id", this.scheduling_id);
    } else {
      this.scheduling_id = localStorage.getItem("scheduling_id");
    }
    this.onload();
  },
};
</script>
<style scoped>
.del {
  margin: 7px;
  background-image: linear-gradient(-0deg, #f4faba, #ff0707);
  color: #fff;
}

.nav-arrow-left {
  margin-right: 5px;
  text-decoration: none;
  color: #fff;
}

.nav-arrow-right {
  margin: 5px;
  text-decoration: none;
  color: rgb(3, 55, 167);
}

.left-arrow {
  color: #fff;
}

.right-arrow {
  color: rgb(3, 55, 167);
}

.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}

.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}

.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}

.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}

.del-card-title {
  background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
  color: #fff;
}

.lbl {
  color: #1705e6;
}
</style>
